import axios from "axios"

export function fileUpload(file, link, changeProgress) {
  const config = {
    method: "PUT",
    url: link,
    headers: {
      "Content-Type": "application/octet-stream"
    },
    data: file,
    onUploadProgress:
      typeof changeProgress === "function"
        ? (progressEvent) => changeProgress(progressEvent.loaded, progressEvent.total, file.name)
        : () => {}
  }
  return axios(config)
}
