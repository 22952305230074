import { createRoot } from "react-dom/client"
import MainComponent from "./App"
import * as Sentry from "@sentry/react"
import { ErrorPlaceholder } from "./components/utils/placeholders"
import "./scss/application.scss"
import "./index.css"
import { IsInsidePowerPointProvider } from "./context/IsInsidePowerPointContext"
import OfficeReady from "@/components/OfficeReady"

import { registerInstrumentations } from "@opentelemetry/instrumentation"
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web"
import { BatchSpanProcessor, WebTracerProvider } from "@opentelemetry/sdk-trace-web"
import { ZoneContextManager } from "@opentelemetry/context-zone"
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-proto"
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions"
import { Resource } from "@opentelemetry/resources"

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: "sc-front"
  })
})

provider.addSpanProcessor(
  new BatchSpanProcessor(
    new OTLPTraceExporter({
      url: `${window.location.protocol}//${window.location.host}/v1/traces`,
      headers: {}
    })
  )
)

provider.register({
  contextManager: new ZoneContextManager()
})

registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      "@opentelemetry/instrumentation-fetch": {
        propagateTraceHeaderCorsUrls: [new RegExp("/graphql")]
      }
    })
  ]
})

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    //   Sentry.browserTracingIntegration({
    //     // disable automatic span creation
    //     instrumentNavigation: false,
    //     instrumentPageLoad: false
    //   }),
    Sentry.replayIntegration({ networkDetailAllowUrls: ["api.slidecamp.io", "api2.slidecamp.io"] })
  ],
  tracesSampleRate: 1.0,
  // tracePropagationTargets: ["bum.slidecamp", "api2.slidecamp"],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0
})

if (import.meta.env.PROD && window.location.href.search("appspot.com") === -1 && import.meta.env.VITE_MIXPANEL_TOKEN) {
  window.mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN)
}

document.body.addEventListener(
  "error",
  (event) => {
    if (!event.target) return

    if (event.target.tagName === "IMG") {
      Sentry.captureMessage(`Failed to load image: ${event.target.src}`, "warning")
    } else if (event.target.tagName === "LINK") {
      Sentry.captureMessage(`Failed to load css: ${event.target.href}`, "warning")
    }
  },
  true // useCapture - necessary for resource loading errors
)

const App = () => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorPlaceholder
          tryAgain={() => {
            window.location.replace("/")
          }}
        />
      }
    >
      <IsInsidePowerPointProvider>
        <OfficeReady>
          <MainComponent />
        </OfficeReady>
      </IsInsidePowerPointProvider>
    </Sentry.ErrorBoundary>
  )
}

const root = createRoot(document.getElementById("app"))
root.render(<App />)
