import { gql } from "@apollo/client"

// removed fields from query after moving to sc-api, this call is not protected by auth
// so the number of fields queried was reduced
export const SharedBatchData = gql`
  query batchByShareToken($shareToken: String!) {
    batchByShareToken(shareToken: $shareToken) {
      id
      batchId
      name
      icon
      # isFavourite
      size
      labels
      thumbUrl
      progress
      # deleted
      # uploaded
      # mode
      # sharedForMembers {
      #   id
      # }
      # sharedForUnits {
      #   id
      # }
      pptx
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        texts
        name
        tags
        # isFavourite
        # downloadUrl
        linksDataHeight
        linksDataWidth
        state
        linksData {
          URL
          IsTextLink
          Positions {
            Left
            Top
            Width
            Height
            Text
          }
        }
      }
    }
  }
`

export const CheckMerging = gql`
  query checkMerging($batchId: String!) {
    session {
      checkMerging(batchId: $batchId)
    }
  }
`

export const UserData = gql`
  query getUser($unstable: Float) {
    session(unstable: $unstable) {
      authToken
      sessionOwner {
        id
        userId
        firstName
        lastName
      }
      user {
        id
        userId
        firstName
        lastName
        email
        admin
        trialTo
        teamOwner
        hideBilling
        showBar
        canBuySlides
        role
        isEditModeActive
        group {
          _id
          id
          groupId
          custom
          urlPrefix
          searchTags
        }
        subscription {
          id
          subscriptionId
          active
          payedTo
          plan {
            id
            planId
            interval
            name
          }
        }
      }
    }
  }
`

export const InviteesData = gql`
  query invitees {
    invitees {
      id
      email
      invitationLink
    }
  }
`

export const PricingData = gql`
  query getPricing($unstable: Float) {
    session(unstable: $unstable) {
      authToken
      user {
        id
        group {
          id
          groupId
          plans {
            id
            planId
            name
            amount
            interval
            teamMembers
            description
            accessToken
          }
        }
        subscription {
          id
          active
          plan {
            id
            planId
          }
        }
      }
    }
  }
`

export const BillingInfoData = gql`
  query getBilling($unstable: Float) {
    session(unstable: $unstable) {
      authToken
      user {
        id
        userId
        subscription {
          id
          subscriptionId
          payedTo
          willBeCanceled
          plan {
            id
            planId
            interval
            name
            amount
          }
        }
        billingData {
          contactPerson
          email
          companyName
          companyDetails
        }
        invoices {
          id
          invoiceId
          date
          number
          downloadUrl
        }
      }
    }
  }
`

export const GroupsData = gql`
  query getGroups {
    admin {
      groups {
        groupId
      }
    }
  }
`

export const AdminData = gql`
  query getAdminData {
    admin {
      groups {
        id
        groupId
        name
        custom
        urlPrefix
        plans {
          id
          planId
        }
        searchTags
        themes {
          id
          themeId
        }
      }
      plans {
        id
        planId
        name
        amount
        interval
        teamMembers
        description
      }
      themes {
        id
        themeId
        name
      }
    }
  }
`

export const OrganizationsData = gql`
  query getOrganizations {
    getOrganizations {
      success
      message
      code
      organizations {
        id
        name
        urlPrefix
        owner {
          _id
          acceptTeamOwnerInvitationToken
        }
      }
    }
  }
`

export const OrganizationsSlidesStatistics = gql`
  query getOrganizationsSlidesStatistics {
    organizationsSlidesStatistics {
      groupName
      total
      resolutions {
        label
        count
        percentage
      }
    }
  }
`

export const AdminUserData = gql`
  query getAdminData($limit: Int, $offset: Int, $phrase: String) {
    admin {
      users(limit: $limit, offset: $offset, phrase: $phrase) {
        totalCount
        list {
          id
          userId
          firstName
          lastName
          email
          admin
          group {
            id
            groupId
          }
          teamOwner
          subscription {
            id
            subscriptionId
            active
            willBeCanceled
            plan {
              id
              planId
              amount
              interval
            }
          }
          allowUploads
        }
      }
    }
  }
`

export const SingleBatchData = gql`
  query getGroups($batchId: String!) {
    session {
      authToken
      user {
        batch(batchId: $batchId) {
          id
          batchId
          isFavourite
        }
      }
    }
  }
`

export const checkOrganizationOwnerInvitationStatus = gql`
  query checkOrganizationOwnerInvitationStatus($token: String!) {
    checkOrganizationOwnerInvitationStatus(token: $token) {
      email
      status
    }
  }
`

export const ShareUploadData = gql`
  query getShowBatch($unstable: Float) {
    session(unstable: $unstable) {
      authToken
      user {
        id
        teamOwner
        team {
          id
          members {
            id
            userId
            firstName
            lastName
            email
          }
          units {
            id
            unitId
            name
          }
        }
      }
    }
  }
`

export const logs = gql`
  query logs($limit: Int, $offset: Int) {
    session {
      user {
        id
        teamOwner
      }
    }
    logs(limit: $limit, offset: $offset) {
      inviteNewUserToTeam {
        timestamp
        senderFirstName
        senderLastName
        invitedUserEmail
      }
      openBatchByShareToken {
        timestamp
        batchName
        viewerCity
        viewerRegion
        viewerCountry
      }
      setBatchSharing {
        timestamp
        batchId
        batchName
        sharerId
        sharerFirstName
        sharerLastName
        groupId
        userIds
        userFirstNames
        userLastNames
        unitIds
        unitNames
        state
        slides {
          id
          thumbnail
        }
      }
      searchPerformed {
        timestamp
        userId
        groupId
        userFirstName
        userLastName
        query
        slides {
          id
          thumbnail
        }
      }
      uploadedIntoTemplate {
        timestamp
        userFirstName
        userLastName
        batchName
        slides {
          id
          thumbnail
        }
      }
      totalCount
    }
  }
`

export const GET_JOB = gql`
  query job($queueName: String!, $jobIds: [String!]!) {
    job(queueName: $queueName, jobIds: $jobIds) {
      id
      queueName
      data {
        isReady
      }
      lifecycle {
        status
        progress
        error
      }
    }
  }
`

export const PRESENTATION_URL = gql`
  query presentationUrl($id: String!) {
    presentationUrl(id: $id)
  }
`
