import { useState, useRef, useEffect } from "react"

export const Tooltip = ({ text, children, position = "top" }) => {
  const [isVisible, setIsVisible] = useState(false)
  const tooltipRef = useRef()

  const showTooltip = () => setIsVisible(true)
  const hideTooltip = () => setIsVisible(false)

  useEffect(() => {
    const element = tooltipRef.current
    if (element) {
      element.addEventListener("mouseover", showTooltip)
      element.addEventListener("mouseleave", hideTooltip)
    }
    return () => {
      if (element) {
        element.removeEventListener("mouseover", showTooltip)
        element.removeEventListener("mouseleave", hideTooltip)
      }
    }
  }, [])

  const tooltipStyles = {
    position: "absolute",
    zIndex: 1000,
    [position === "top" ? "bottom" : "top"]: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    marginBottom: position === "top" ? "8px" : "0",
    marginTop: position === "bottom" ? "8px" : "0",
    backgroundColor: "#fff",
    color: "#000",
    padding: "5px 10px",
    borderRadius: "5px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    border: "1px solid #ccc",
    fontSize: "12px",
    whiteSpace: "nowrap"
  }

  return (
    <span className="relative" ref={tooltipRef} style={{ display: "inline-block", position: "relative" }}>
      {children}
      {isVisible && (
        <div className="tooltip-content" style={tooltipStyles}>
          {text}
        </div>
      )}
    </span>
  )
}

export const Ellipsis = ({ text, length, tooltip = true }) => {
  const textString = text.toString()
  const isTruncated = textString.length > length
  const cutText = textString.substring(0, length)

  if (!tooltip) {
    return <span className="line-clamp-1">{isTruncated ? `${cutText}...` : textString}</span>
  }

  return (
    <Tooltip text={isTruncated ? text : ""}>
      <span className="line-clamp-1">{isTruncated ? `${cutText}...` : textString}</span>
    </Tooltip>
  )
}
