import { useParams, useHistory } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { UserDataContext } from "@/hooks/UserDataHook"
import { CategoryContext } from "@/context/CategoryContext"
import slugify from "slug"
import CollectionSection from "./CollectionSection"
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
  closestCorners
} from "@dnd-kit/core"
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import CustomDisclosure from "@/components/utils/CustomDisclosure"

const CategoryPage = () => {
  const history = useHistory()
  const { urlToken } = useParams()
  const {
    user,
    user: { isEditModeActive }
  } = useContext(UserDataContext)
  const { getCategory, loading, categories, changePresentationPosition, setCollapsed, reorderCategory } =
    useContext(CategoryContext)
  const { cart } = user
  const [openedBatch, setOpenedBatch] = useState(null)

  const [activeDrag, setActiveDrag] = useState(null)
  const [category, setCategory] = useState(null)

  const [isCollapsed, setIsCollapsed] = useState(null)
  const [areCollectionsHidden, setAreCollectionsHidden] = useState(false)
  const [arePresentationsHidden, setArePresentationsHidden] = useState(false)

  useEffect(() => {
    if (categories.length) {
      setCategory(getCategory(urlToken))
    }
  }, [urlToken, categories])

  useEffect(() => {
    setIsCollapsed({
      Collections: category?.isCollectionsCollapsed,
      Presentations: category?.isPresentationsCollapsed
    })
  }, [category])

  useEffect(() => {
    const selectedPresentation = category?.presentations.find((batch) => batch.urlToken === urlToken)
    const selectedCollection = category?.collections.find((batch) => batch.urlToken === urlToken)
    if (selectedPresentation) {
      setOpenedBatch({
        type: "Presentations",
        urlToken: selectedPresentation.urlToken
      })
    } else if (selectedCollection) {
      setOpenedBatch({
        type: "Collections",
        urlToken: selectedCollection.urlToken
      })
    }
  }, [urlToken])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function findContainer(id, fieldName) {
    if (category?._id === id) {
      return category
    } else if (category?.subCategories.find((elem) => elem._id === id)) {
      return category?.subCategories.find((elem) => elem._id === id)
    } else if (category[fieldName].find((item) => item.id === id)) {
      return category
    } else {
      for (const sub of category.subCategories) {
        if (sub[fieldName].find((elem) => elem.id === id)) {
          return sub
        }
      }
    }
  }

  function handleDragStart(event) {
    const { active } = event
    const {
      data: { current }
    } = active

    if (current.sortable.containerId === "subcategories") {
      if (current.type === "collection") {
        setAreCollectionsHidden(true)
      } else {
        setArePresentationsHidden(true)
      }
      return
    }
    setActiveDrag(current)
  }

  function handleDragMove(event) {
    const { active, over } = event

    if (!active || !over) {
      return
    }

    const {
      id,
      data: { current }
    } = active
    const { id: overId } = over

    if (current.sortable.containerId === "subcategories") {
      return
    }
    const fieldName = current.icon ? "collections" : "presentations"
    const activeContainer = findContainer(id, fieldName)
    const overContainer = findContainer(overId, fieldName)

    if (!activeContainer || !overContainer || activeContainer._id === overContainer._id) {
      return
    }

    const activeItems = activeContainer[fieldName]
    const overItems = overContainer[fieldName]
    const activeIndex = activeItems.findIndex((item) => item.id === id)
    const overIndex = overItems.findIndex((item) => item.id === overId)

    const activeItem = { ...activeContainer[fieldName][activeIndex] }

    if (overContainer._id === category?._id) {
      const updated = structuredClone(category)

      updated[fieldName] = [
        ...overContainer[fieldName].slice(0, overIndex),
        { ...activeItem, category: { ...activeItem.category, _id: updated._id } },
        ...overContainer[fieldName].slice(overIndex)
      ]
      for (const index in updated.subCategories) {
        if (updated.subCategories[index]._id === activeContainer._id) {
          updated.subCategories[index][fieldName] = activeContainer[fieldName].filter((item) => item.id !== active.id)
        }
      }
      setCategory(updated)
    } else if (activeContainer._id === category?._id) {
      const updated = structuredClone(category)

      updated[fieldName] = activeContainer[fieldName].filter((item) => item.id !== active.id)
      for (const index in updated.subCategories) {
        if (updated.subCategories[index]._id === overContainer._id) {
          updated.subCategories[index][fieldName] = [
            ...overContainer[fieldName].slice(0, overIndex),
            { ...activeItem, category: { ...activeItem.category, _id: updated.subCategories[index]._id } },
            ...overContainer[fieldName].slice(overIndex)
          ]
        }
      }
      setCategory(updated)
    } else {
      const updated = structuredClone(category)

      for (const index in updated.subCategories) {
        if (updated.subCategories[index]._id === overContainer._id) {
          updated.subCategories[index][fieldName] = [
            ...updated.subCategories[index][fieldName].slice(0, overIndex),
            { ...activeItem, category: { ...activeItem.category, _id: updated.subCategories[index]._id } },
            ...updated.subCategories[index][fieldName].slice(overIndex)
          ]
        } else if (updated.subCategories[index]._id === activeContainer._id) {
          updated.subCategories[index][fieldName] = activeContainer[fieldName].filter((item) => item.id !== active.id)
        }
      }

      setCategory(updated)
    }
  }

  function handleDragEnd(event) {
    const { active, over } = event

    setActiveDrag(null)
    setAreCollectionsHidden(false)
    setArePresentationsHidden(false)

    if (!active || !over) {
      return
    }
    const {
      id,
      data: { current }
    } = active
    const {
      id: overId,
      data: { current: overCurrent }
    } = over

    if (current.sortable.containerId === "subcategories") {
      if (overCurrent.type && id !== overId) {
        const activeIndex = category.subCategories.findIndex((sub) => sub._id === id.split("_")[1])
        const overIndex = category.subCategories.findIndex((sub) => sub._id === overId.split("_")[1])
        const updated = structuredClone(category)
        updated.subCategories = arrayMove(updated.subCategories, activeIndex, overIndex)
        setCategory(updated)
        reorderCategory(id.split("_")[1], overId.split("_")[1])
      }
      return
    }
    const fieldName = active.data.current.icon ? "collections" : "presentations"
    const activeContainer = findContainer(id, fieldName)
    const overContainer = findContainer(overId, fieldName)
    if (!activeContainer || !overContainer) {
      return
    }
    const activeIndex = activeContainer[fieldName].findIndex((item) => item.id === id)
    const overIndex = overContainer[fieldName].findIndex((item) => item.id === overId)
    const activeItem = { ...activeContainer[fieldName][activeIndex] }
    const updated = structuredClone(category)

    if (activeIndex !== overIndex) {
      if (category?._id === activeContainer._id) {
        updated[fieldName] = arrayMove(category[fieldName], activeIndex, overIndex)
      } else {
        for (const index in updated.subCategories) {
          if (updated.subCategories[index]._id === activeContainer._id) {
            updated.subCategories[index][fieldName] = arrayMove(
              updated.subCategories[index][fieldName],
              activeIndex,
              overIndex
            )
          }
        }
      }
      setCategory(updated)
      changePresentationPosition(activeItem._id, overContainer._id, overIndex, updated)
    } else if (activeDrag.category._id !== overContainer._id) {
      changePresentationPosition(activeItem._id, overContainer._id, overIndex, updated)
    }
  }

  if (
    (loading && !categories.length) ||
    (categories.length && !categories[0].urlToken && !urlToken) ||
    !categories.length
  ) {
    return null
  } else if (urlToken && !category && categories.length) {
    return <h1>Category not found</h1>
  } else if (categories.length && !urlToken) {
    return history.push(`/library/${categories[0].urlToken}/${slugify(categories[0].name)}`)
  }

  const handleExpand = async (presentationsName) => {
    if (!isCollapsed?.[presentationsName] && presentationsName === openedBatch?.type) {
      history.push(`/library/${category.urlToken}/${slugify(category.name)}`)
      setOpenedBatch(null)
    }
    setIsCollapsed({ ...isCollapsed, [presentationsName]: !isCollapsed?.[presentationsName] })
    await setCollapsed(presentationsName, category._id, !isCollapsed?.[presentationsName])
  }

  const allowShowingPresentations =
    !!category?.presentations.length ||
    category?.subCategories.find((sub) => sub.presentations.find((batch) => batch.mode === "slides"))

  const allowShowingCollections =
    !!category?.collections.length ||
    category?.subCategories.find((sub) => sub.collections.find((batch) => batch.mode === "blueprints"))

  return (
    <>
      <div className={"pb-[56px] pt-[25px]"}>
        {/*
        Do not remove the following code
        https://github.com/imprv/slidecamp/issues/499
      */}
        {/* <div className="flex justify-between items-center mt-9 mb-6 ml-[18px]">
        {canDelete && category?.name !== readOnlyCategoryName && (
          <button
            className="transition ease-in-out hover:text-[#3390ff]"
            onClick={() => {
              removeCategory(category?._id)
            }}
          >
            - Delete category
          </button>
        )}
      </div> */}
        <div className="flex flex-col gap-[25px]">
          {/* {category?.name !== "Team Presentations" &&
              category?.name !== "Opportunities and Packages" && // LA Times
              category?.name !== "Shared Presentations" && // mindfoundry
              category?.name !== "Insurance" &&
              category?.name !== "Defence" &&
              category?.name !== "Miscellaneous" &&
              category?.name !== "Japan" &&
              category?.name !== "General Presentations" && ( // 365retailmarkets */}
          {(isEditModeActive || allowShowingCollections) && (
            <DndContext
              collisionDetection={closestCorners}
              measuring={{
                droppable: {
                  strategy: MeasuringStrategy.Always
                }
              }}
              onDragEnd={handleDragEnd}
              onDragMove={handleDragMove}
              onDragStart={handleDragStart}
              sensors={sensors}
            >
              <CustomDisclosure
                buttonText="Collections"
                handleExpand={() => handleExpand("Collections")}
                open={!isCollapsed?.Collections}
              >
                <CollectionSection
                  active={activeDrag}
                  areBatchesHidden={areCollectionsHidden}
                  batchesName="Collections"
                  category={category}
                  Icon="Presentation"
                  id={category?._id}
                  isEditModeActive={isEditModeActive}
                  isSection={false}
                  name="Collection"
                  openedBatch={openedBatch}
                  relevantBatches={category?.collections}
                  setOpenedBatch={setOpenedBatch}
                />
              </CustomDisclosure>

              {/* )} */}

              {/* {category?.name !== "Slides" &&
              category?.name !== "Core Decks" && // LA Times
              category?.name !== "North America" && // CTM
              category?.name !== "EMEA" &&
              category?.name !== "APAC" &&
              category?.name !== "ANZ" &&
              category?.name !== "Building Blocks" && // mindfoundry
              category?.name !== "AHS" && // Frontdoor
              category?.name !== "HSA" &&
              category?.name !== "Frontdoor" && // CTM
              category?.name !== "F  or Operators" && // 365retailmarkets
              category?.name !== "For End Clients" &&
              category?.name !== "Internal Only" && ( */}
            </DndContext>
          )}
          {(isEditModeActive || (allowShowingPresentations && allowShowingCollections)) && (
            <div
              className={`w-full flex justify-center mobile-xs:px-[24px] ${
                cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"
              }`}
            >
              <div className="w-full border-b border-sc-line desktop-big:w-[1812px]" />
            </div>
          )}
          {(isEditModeActive || allowShowingPresentations) && (
            <DndContext
              collisionDetection={closestCorners}
              measuring={{
                droppable: {
                  strategy: MeasuringStrategy.Always
                }
              }}
              onDragEnd={handleDragEnd}
              onDragMove={handleDragMove}
              onDragStart={handleDragStart}
              sensors={sensors}
            >
              <CustomDisclosure
                buttonText="Presentations"
                handleExpand={() => handleExpand("Presentations")}
                open={!isCollapsed?.Presentations}
              >
                <CollectionSection
                  active={activeDrag}
                  areBatchesHidden={arePresentationsHidden}
                  batchesName="Presentations"
                  category={category}
                  id={category?._id}
                  isEditModeActive={isEditModeActive}
                  isSection={false}
                  name="Presentation"
                  openedBatch={openedBatch}
                  relevantBatches={category?.presentations}
                  setOpenedBatch={setOpenedBatch}
                />
              </CustomDisclosure>
            </DndContext>
          )}
        </div>
        {/*
        Do not remove the following code
        https://github.com/imprv/slidecamp/issues/499
      */}
      </div>
    </>
  )
}

export default CategoryPage
