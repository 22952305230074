import { useMutation } from "@apollo/client"

import { autoCreateBatches } from "../../graphql/mutations"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { isRequired } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"
import { UploadFormProps } from "./types"

const UploadLibraryForm = ({ groups, initialValues, wizard }: UploadFormProps) => {
  const [_autoCreateBatches, { loading }] = useMutation(autoCreateBatches)

  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "group",
      validators: [isRequired],
      initialValue: initialValues ? initialValues.group : undefined
    }
  ])

  const submitForm = async () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    const { group } = values

    _autoCreateBatches({
      variables: {
        groupId: group,
        sets: []
      }
    })
  }

  return (
    <div className="admin-upload-templates">
      <form>
        <div className="row">
          <div style={{ paddingLeft: "15px" }}>
            <div>
              <InputWrapper error={validations.group} label="Group">
                <select disabled={wizard} name="group" onChange={onChange} value={values.group}>
                  <option />
                  {groups.map((group) => (
                    <option key={group.groupId} value={group.groupId}>
                      {group.groupId}
                    </option>
                  ))}
                </select>
              </InputWrapper>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingLeft: "15px" }}>
          <div className="admin-button-submit">
            <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UploadLibraryForm
